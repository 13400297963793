export const navigationLinks = () => [
	{
		key: 'about-me',
		icon: 'fa-solid fa-info-circle',
		title: 'About me'
	},
	{
		key: 'skills',
		icon: 'fa-solid fa-graduation-cap',
		title: 'Skills'
	}
];
